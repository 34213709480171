export const FETCH_RECS = 'FETCH_RECS';
export const UPDATE_RECS_LOADED = 'UPDATE_RECS_LOADED';
export const UPDATE_RECS_LOADED_ALL = 'UPDATE_RECS_LOADED_ALL';
export const UPDATE_REPEAT_ITEMS = 'UPDATE_REPEAT_ITEMS';
export const UPDATE_BEST_SELLER_ITEMS = 'UPDATE_BEST_SELLER_ITEMS';
export const UPDATE_COMPLETE_MEAL_ITEMS = 'UPDATE_COMPLETE_MEAL_ITEMS';
export const UPDATE_FREQUENTLY_ADD_WITH_ITEM = 'UPDATE_FREQUENTLY_ADD_WITH_ITEM';
export const RESET_RECOMMENDATIONS = 'RESET_RECOMMENDATIONS';
export const RECOMMENDATIONS_ALGONOMY = 1;
export const RECOMMENDATIONS_EASYEAT = 2;
export const BEST_SELLER_ITEMS = 1;
export const REPEAT_ITEMS = 2;
export const FREQUENTLY_ADD_WITH_ITEM = 3;
export const COMPLETE_MEAL_ITEMS = 4;
