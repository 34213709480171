export const CURRENT_RESTAURANT = 'current_restaurant';
export const CURRENT_MALL = 'current_mall';
export const ORDER_TYPE = '_order_type';
export const CHECKOUT = 'checkout';
export const OVO = 'ovo';

export const RESTAURANT_TYPE = 'RESTAURANT_TYPE';

export const ASAP = 'ASAP';
export const SCHEDULED = 'SCHEDULED';
export const PAYMENT_RESPONSE_STATUS = {
  SUCCESS: 1,
  INCOMPLETE: 0,
  FAILURE: 2,
};

// Payment enums
export const PAYMENT_CHANNEL = {
  GOPAY: 'gopay',
  OVO: 'ovo-emoney',
  GRABPAY: 'grab_pay_app',
  PNCO: 'PNCO',
};
export const PAYMENT_GATEWAY = {
  MIDTRANS: 'midtrans',
  DOKU: 'doku',
  GRABPAY: 'grabpay',
};
export const PAYMENT_PROCESS = {
  DIRECT: 'direct',
  CHECKOUT: 'checkout',
};
export const CHANNEL_IDS = {
  GOPAY: 'gopay_midtrans',
  OVO: 'doku-ovo-emoney',
};

export const PAYMENT_MODE = {
  FAST: 'fast',
  SECURE: 'secure',
};

export const PAYMENT_SCREEN = {
  PNCO: 'pnco',
  RAZER: 'razer',
};

export const TYPE_INDEX = {
  GO_PAY: 'gopay',
  CARD: 'Card',
  CASH: 'Cash',
  CASH_KI: 'CASH_KIOSK',
  PAY_LATER: 'pay_later',
  WALLET: 'Wallet',
  NET_BANKING: 'Net Banking',
};
export const SKIP_PACKING_CHARGES = '_skip_packaging_charge_operation';
export const PREVIOUS_PATH = 'previous_path';
export const MENU_PATH = 'menu_path';
export const BACK_PAGE = 'back_page';
export const BACK_PATH = 'back_path';
export const PICKUP = 'pu';
export const TAKEAWAY = 'ta';
export const TABLE_ID_TAKEAWAY = 'takeaway';

export const removeMall = { removeMall: true };

export const MENU = 'menu';
export const CART = 'cart';
export const PAYMENT = 'payment';
export const ORDER_STATUS = 'order_status';

export const LOGIN_FROM_CART = 'login_from_cart';

export const PAY_LATER = 'pay_later_';
export const DINEIN_TYPE = '0';
export const DELIVERY_TYPE = '1';
export const PICKUP_TYPE = '2';

export const ASK_TO_USE_APP = '_ask_to_use_app';
export const ERROR_MSG = 'error_msg';
export const REDIRECT_URL_STATE = 'redirect_url_state';
export const LITE = 'lite';
export const LITE_DEL = 'lite_del';
export const PRO = 'pro';
export const PRO_SUBS = 'pro_subs';
export const LOCKED_PLAN = 'EE_FreeDigitalMenuLeads';

export const ORDER_STATUS_MAP = {
  COMPLETE: 4,
  CANCELLED: 6,
};
