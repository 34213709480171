import {
  FETCH_RECS,
  BEST_SELLER_ITEMS,
  COMPLETE_MEAL_ITEMS,
  FREQUENTLY_ADD_WITH_ITEM,
  REPEAT_ITEMS,
  RESET_RECOMMENDATIONS,
  UPDATE_BEST_SELLER_ITEMS,
  UPDATE_COMPLETE_MEAL_ITEMS,
  UPDATE_FREQUENTLY_ADD_WITH_ITEM,
  UPDATE_RECS_LOADED,
  UPDATE_RECS_LOADED_ALL,
  UPDATE_REPEAT_ITEMS,
} from './types';

const initialState = {
  repeatItems: [],
  bestSellerItems: [],
  completeMealItems: [],
  frequentlyBoughtWithItem: {},
  bestSellerLoaded: false,
  repeatItemsLoaded: false,
  frequentlyBoughtWithItemLoaded: false,
  completeMealItemsLoaded: false,
};

const RecommendationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECS:
      return {
        ...state,
        [action?.payload?.type]: false,
      };
    case UPDATE_RECS_LOADED:
      return {
        ...state,
        [action?.payload?.type]: true,
      };
    case UPDATE_RECS_LOADED_ALL:
      return {
        ...state,
        bestSellerLoaded: true,
        repeatItemsLoaded: true,
        frequentlyBoughtWithItemLoaded: true,
        completeMealItemsLoaded: true,
      };
    case UPDATE_REPEAT_ITEMS:
      return {
        ...state,
        repeatItems: action.payload,
      };
    case UPDATE_BEST_SELLER_ITEMS:
      return {
        ...state,
        bestSellerItems: action.payload,
      };
    case UPDATE_COMPLETE_MEAL_ITEMS:
      return {
        ...state,
        completeMealItems: action.payload,
      };
    case UPDATE_FREQUENTLY_ADD_WITH_ITEM:
      return {
        ...state,
        frequentlyBoughtWithItem: { ...state.frequentlyBoughtWithItem, ...action.payload },
      };
    case RESET_RECOMMENDATIONS:
      if (action?.payload?.length) {
        return {
          ...state,
          ...(action?.payload.includes(BEST_SELLER_ITEMS) && { bestSellerItems: [] }),
          ...(action?.payload.includes(REPEAT_ITEMS) && { repeatItems: [] }),
          ...(action?.payload.includes(FREQUENTLY_ADD_WITH_ITEM) && { frequentlyAddWithItem: [] }),
          ...(action?.payload.includes(COMPLETE_MEAL_ITEMS) && { completeMealItems: [] }),
        };
      }
      return initialState;
    default:
      return { ...state };
  }
};

export default RecommendationsReducer;
